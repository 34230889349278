<template>
  <div>
    <div class="section section-1">
      <div class="content">
        <div class="text">
          <div class="text-1 color-orange">店教授云后台</div>
          <div class="text-2 color-white">像大企业一样，管理你的门店</div>
          <div class="text-3 color-white">
            营业报告 | 会员与营销 | 进销存 | 供应链
          </div>
          <!-- <div class="text-4 color-white">
            <span style="font-size: 28px">¥</span>
            <span style="font-size: 38px">1</span>
            <span style="font-size: 15px">/天</span>
          </div> -->
          <div>
            <a
              id="experience"
              class="experience color-white"
              href="http://pos.yunlankeji.com/"
              target="_blank"
              >立即体验</a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="section section-2">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-black">系统后台</div>
          <div class="section-text-2 color-black">
            50+种报表，500强企业也能用
          </div>
          <div class="section-text-3 color-black">
            店教授云后台内置门店所需的多种营业报表，联手国际知名咨询公司A.S.Louken潜心研发，从
            <br />
            单店到连锁都能用，帮助您分析生意、进行连锁管理，并做出科学的决策。
          </div>

          <div class="text-4 color-black">业绩分析</div>
          <div class="text-5 color-black">占比统计</div>
          <div class="text-6 color-black">会员报表</div>
          <div class="text-7 color-black">折让统计</div>
        </div>
      </div>
    </div>

    <div class="section section-3">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-black">我的店铺APP</div>
          <div class="section-text-2 color-black">无需到店，即可掌控一切</div>
          <div class="section-text-3 color-black">
            担心门店状况而不敢外出？
            <br />
            下载我的店铺APP，就算外出度假，一样能够监控店铺的情
            <br />
            况，销售数据、库存信息、实时监控录像等尽在掌握。
          </div>

          <div class="text-4 color-black">
            <a class="color-black" href="">了解更多 ></a>
          </div>

          <div class="text-5 color-black">
            <a class="app-ios color-white">
              <span class="link-text">iOS版本下载</span>
            </a>
            <a class="app-android color-white">
              <span class="link-text">安卓版本下载</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div id="section-4" class="section section-4">
      <div class="content">
        <div class="text">
          <div class="link link-1">
            <a id="catering-edition" href="javascript:void(0);"
              >餐饮业 - 原材料配方管理</a
            >
          </div>
          <div id="content-1" class="content content-1">
            <div class="section-text-1 color-black">原材料配方管理</div>
            <div class="section-text-2 color-black mr30">
              精确到克的损耗，
            </div>
            <div class="section-text-2 color-black mr30">
              都将计入成本
            </div>
            <div class="section-text-3 color-black">
              销售额－成本＝利润？
              <br />
              在发现利润原地踏步时，或许应该看看损耗。店教授收银系统
              <br />
              支持菜品按原材料组合管理，这能帮你做到口味标准化厨师
              <br />
              只需按配方烧菜，从而不必担心多门店口味不一。
              <br />
              同时，按克精确控制原料损耗，能够让菜品消耗与原材料挂
              <br />
              钩，帮您从源头管理门店成本。
            </div>
            <div class="text-4 color-black">
              <a class="color-black" href="" target="_blank">更多餐饮业干货 ></a>
            </div>
          </div>

          <div class="link link-2">
            <a id="retailing-edition" href="javascript:void(0);"
              >零售业 - 进销存管理</a
            >
          </div>
          <div id="content-2" class="content content-2">
            <div class="section-text-1 color-black">进销存</div>
            <div class="section-text-2 color-black">
              建档不再耗时间，
              <br />
              订货不再凭经验
            </div>
            <div class="section-text-3 color-black">
              数千种商品建档入库要多少时间？什么时候应该订货？
              <br />
              店教授收银系统支持扫码自动生成商品信息，中小超市1日建
              <br />
              档入库。此外，店教授收银系统能够为你做到实时库存预警，
              <br />
              如果您拥有多家门店，即可根据库存信息实现门店间商品自
              <br />
              由调货。如果你的供应商也用店教授，你就能实现一站式订货、
              <br />
              追踪货流以及结算。
            </div>
            <div class="text-4 color-black">
              <a class="color-black" href="">更多零售业干货 ></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-5">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-black">会员营销功能</div>
          <div class="section-text-2 color-black mr30">
            多一个回头客，
          </div>
          <div class="section-text-2 color-black mr30">
            少发几百张传单
          </div>
          <div class="section-text-3 color-black">
            无需羡慕大企业的CRM ，现在您可以设置会员积分制度，
            <br />
            并根据不同的客户设置充值送、满减和返现 活动，实实在在
            <br />
            回流更多资金。
            <br />
            此外，店教授收银系统能够帮您分析潜水会员，一键设置短信
            <br />
            推送，利用系统内置的促销功能实现激活。如果您拥有微信
            <br />
            公众号，更能帮您打通门店会员与微信粉丝数据，实现优惠
            <br />
            信息实时推送。
          </div>
        </div>
      </div>
    </div>

    <div class="section section-6">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-black">员工管理</div>
          <div class="section-text-2 color-black mr30">员工业绩，一目了然</div>
          <div class="section-text-3 color-black">
            还在手工核算员工业绩？
            <br />
            通过店教授收银系统，你可以知道任意时间、所有门店
            <br />
            销售人员的业绩水平，还能帮你管理销售人员业绩提
            <br />
            成，无需依靠手工记账。
          </div>
        </div>
      </div>
    </div>

    <div class="section section-7">
      <div class="content">
        <div class="text">
          <div class="section-text-1 color-white">网店与微信店铺</div>
          <div class="section-text-2 color-white mr30">轻松搞定网络推广那些事</div>
          <div class="section-text-3 color-white">
            微信公众号无人问津？
            <br />
            聘请营销团队或技术外包来推广门店，可是一笔不小的花销。店教授云
            <br />
            后台的网店功能，不仅能够帮您自主搭建网店，无缝嵌入微信公众号，实现微信外卖点餐、
            <br />
            自助点餐，让你的微信号瞬间变身专属APP。
          </div>
          <div class="text-4">
            <a class="color-white" href="">了解更多，告别公众号无人问津 ></a>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-8">
      <div class="content">
        <div class="text">
          <div class="title color-orange">选择店教授，帮你赚钱的生意管家</div>

          <div class="sub-text-1">
            <div class="text-1 color-orange">跨平台，多设备</div>
            <div class="text-2 color-black">
              你可以在Windows、iOS、安卓使用银店教授进
              <br />
              行收银和生意管理，无需额外购买收银机。
            </div>
          </div>

          <div class="sub-text-2">
            <div class="text-1 color-orange">零IT成本</div>
            <div class="text-2 color-black">
              你将获得一套含收银系统、ERP、CRM、
              <br />
              SCM的业务管理系统，更新和配套服务都
              <br />
              交给我们，你无需额外IT支出。
            </div>
          </div>

          <div class="sub-text-3">
            <div class="text-1 color-orange">享受企业级服务</div>
            <div class="text-2 color-black">
              店教授提供专业的咨询顾问，为你提供远程
              <br />
              协助，您可以选择电话、QQ、微信等任意
              <br />
              方式联系到我们。
            </div>
          </div>

          <div id="feature-open" class="feature-open color-orange">
            <div>查看所有功能</div>
          </div>
        </div>
      </div>
    </div>

    <div id="feature" class="section section-9 feature">
      <div class="content">
        <div class="feature-content">
          <div id="feature-skip" class="feature-skip color-orange">
            <div>跳过功能介绍</div>
          </div>

          <div class="feature-title color-orange">店教授云后台</div>

          <div class="feature-subtitle color-black">商品管理系统</div>

          <div class="feature-cell color-gray">○ 商品分类管理</div>
          <div class="feature-cell color-gray">○ 商品基础信息管理</div>
          <div class="feature-cell color-gray">○ 商品价格管理</div>
          <div class="feature-cell color-gray">○ 商品积分管理</div>

          <div class="feature-cell color-gray">○ 无码商品管理</div>
          <div class="feature-cell color-gray">○ 口味管理</div>
          <div class="feature-cell color-gray">○ 组装拆分管理</div>
          <div class="feature-cell color-gray">○ 库存管理</div>

          <div class="feature-cell color-gray">○ 盘点管理</div>
          <div class="feature-cell color-gray">○ 货单管理</div>
          <div class="feature-cell color-gray">○ 过期管理</div>
          <div class="feature-cell color-gray">○ 报损管理</div>

          <div class="feature-cell color-gray">○ 原材料管理</div>
          <div class="feature-cell color-gray">○ 商品销售统计报表</div>
          <div class="feature-cell color-gray">○ 商品销售热力图</div>
          <div class="feature-cell color-gray">○ 时价管理</div>

          <div class="feature-cell color-gray">○ 口味管理</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">原材料管理系统</div>

          <div class="feature-cell color-gray">○ 原材料商品管理</div>
          <div class="feature-cell color-gray">○ 原材料配方管理</div>
          <div class="feature-cell color-gray">○ 半成品管理</div>
          <div class="feature-cell color-gray">○ 配方查询</div>

          <div class="feature-cell color-gray">○ 原材料消耗报表</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">会员管理系统</div>

          <div class="feature-cell color-gray">○ 会员等级管理</div>
          <div class="feature-cell color-gray">○ 会员信息管理</div>
          <div class="feature-cell color-gray">○ 会员报表管理</div>
          <div class="feature-cell color-gray">○ 会员卡</div>

          <div class="feature-cell color-gray">○ 退换卡管理</div>
          <div class="feature-cell color-gray">○ 赊账管理</div>
          <div class="feature-cell color-gray">○ 次卡管理</div>
          <div class="feature-cell color-gray">○ 月卡、季卡、年卡管理</div>

          <div class="feature-cell color-gray">○ 购物卡管理</div>
          <div class="feature-cell color-gray">○ 会员生日管理</div>
          <div class="feature-cell color-gray">○ 会员积分管理</div>
          <div class="feature-cell color-gray">○ 会员充值管理</div>

          <div class="feature-cell color-gray">○ 会员精准营销管理</div>
          <div class="feature-cell color-gray">○ 潜水会员管理</div>
          <div class="feature-cell color-gray">○ 会员积分设置</div>
          <div class="feature-cell color-gray">○ 会员消费历史查询</div>

          <div class="feature-cell color-gray">○ 会员购买商品查询</div>
          <div class="feature-cell color-gray">○ 会员标签</div>
          <div class="feature-cell color-gray">● 微信会员管理</div>
          <div class="feature-cell color-gray">● 微信推送服务</div>

          <div class="feature-cell color-gray">○ 会员赊账</div>
          <div class="feature-cell color-gray">○ 短信服务</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">营销管理系统</div>

          <div class="feature-cell color-gray">○ 打折促销管理</div>
          <div class="feature-cell color-gray">○ 套餐营销管理</div>
          <div class="feature-cell color-gray">○ 返现营销管理</div>
          <div class="feature-cell color-gray">○ 搭赠换购营销管理</div>

          <div class="feature-cell color-gray">○ 充值赠送管理</div>
          <div class="feature-cell color-gray">○ 优惠券管理</div>
          <div class="feature-cell color-gray">○ 营销时间段管理</div>
          <div class="feature-cell color-gray">○ 营销对象管理</div>

          <div class="feature-cell color-gray">○ 营销门店设置</div>
          <div class="feature-cell color-gray">○ 营销效果反馈表</div>
          <div class="feature-cell color-gray">○ 折扣统计报表</div>
          <div class="feature-cell color-gray">● 短信服务</div>

          <div class="feature-cell color-gray">○ 微信优惠推送</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">积分管理系统</div>

          <div class="feature-cell color-gray">○ 积分规则设置</div>
          <div class="feature-cell color-gray">○ 积分查询管理</div>
          <div class="feature-cell color-gray">○ 积分兑换管理</div>
          <div class="feature-cell color-gray">○ 积分抵现管理</div>

          <div class="feature-cell color-gray">○ 积分兑换统计报表</div>
          <div class="feature-cell color-gray">○ 积分变动提醒服务</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">供应商管理</div>

          <div class="feature-cell color-gray">● 供应商信息管理</div>
          <div class="feature-cell color-gray">● 供应商结算</div>
          <div class="feature-cell color-gray">● 结算记录查询</div>
          <div class="feature-cell color-gray">● 商品供应商批量设置</div>

          <div class="feature-cell color-gray">● 供应商拆单管理</div>
          <div class="feature-cell color-gray">● 门店供货销售状况查询</div>
          <div class="feature-cell color-gray">● 供应商品报表查询</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">进销存管理系统</div>

          <div class="feature-cell color-gray">○ 订货管理</div>
          <div class="feature-cell color-gray">○ 进货管理</div>
          <div class="feature-cell color-gray">○ 调货管理</div>
          <div class="feature-cell color-gray">○ 退货管理</div>

          <div class="feature-cell color-gray">○ 货流查询汇总</div>
          <div class="feature-cell color-gray">○ 货款管理</div>
          <div class="feature-cell color-gray">○ 推荐订货商品设置</div>
          <div class="feature-cell color-gray">○ 库存查询与管理</div>

          <div class="feature-cell color-gray">○ 订货通知服务</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">称重管理</div>

          <div class="feature-cell color-gray">○ 称重商品设置</div>
          <div class="feature-cell color-gray">○ 条码秤设置</div>
          <div class="feature-cell color-gray">○ 通讯秤设置</div>
          <div class="feature-cell color-gray">○ 收银一体称设置</div>

          <div class="feature-subtitle color-black">支付管理</div>

          <div class="feature-cell color-gray">○ 现金支付管理</div>
          <div class="feature-cell color-gray">○ 会员卡支付管理</div>
          <div class="feature-cell color-gray">○ 银联卡支付管理</div>
          <div class="feature-cell color-gray">○ 微信支付管理</div>

          <div class="feature-cell color-gray">○ 支付宝支付管理</div>
          <div class="feature-cell color-gray">○ 京东支付管理</div>
          <div class="feature-cell color-gray">○ 苹果支付管理</div>
          <div class="feature-cell color-gray">○ 组合支付管理</div>

          <div class="feature-cell color-gray">○ 第三方支付管理</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">线上订单系统</div>

          <div class="feature-cell color-gray">● 线上销售商品设置</div>
          <div class="feature-cell color-gray">● 在线签到奖励设置</div>
          <div class="feature-cell color-gray">● 微信订单管理</div>
          <div class="feature-cell color-gray">● 自助单管理</div>

          <div class="feature-cell color-gray">● 预约单管理</div>
          <div class="feature-cell color-gray">● 线上订单查询统计</div>
          <div class="feature-cell color-gray">○ 主流团购、外卖对接设置</div>
          <div class="feature-cell color-gray">○ 线上支付方式设置</div>

          <div class="feature-cell color-gray">○ 线上充值规则设置</div>
          <div class="feature-cell color-gray">○ 订单配送管理</div>
          <div class="feature-cell color-gray">○ 手机APP订单管理</div>
          <div class="feature-cell color-gray">○ 门店预约</div>

          <div class="feature-subtitle color-black">连锁管理</div>

          <div class="feature-cell color-gray">● 营业汇总</div>
          <div class="feature-cell color-gray">● 门店捆绑设置</div>
          <div class="feature-cell color-gray">● 门店权限设置</div>
          <div class="feature-cell color-gray">● 分店营业报表</div>

          <div class="feature-cell color-gray">● 营销门店设置</div>
          <div class="feature-cell color-gray">● 商品共享设置</div>
          <div class="feature-cell color-gray">● 会员共享设置</div>
          <div class="feature-cell color-gray">● 储值共享管理</div>

          <div class="feature-cell color-gray">● 总仓库管理</div>
          <div class="feature-cell color-gray">● 配货服务</div>
          <div class="feature-cell color-gray">● 门店结算管理</div>
          <div class="feature-cell color-gray">● 跨店库存查询服务</div>

          <div class="feature-cell color-gray">● 通知服务</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">财务报表</div>

          <div class="feature-cell color-gray">○ 营业报表</div>
          <div class="feature-cell color-gray">○ 商品报损报表</div>
          <div class="feature-cell color-gray">○ 会员消费报表</div>
          <div class="feature-cell color-gray">○ 商品销售报表</div>

          <div class="feature-cell color-gray">○ 退货报表</div>
          <div class="feature-cell color-gray">○ 日结报表</div>
          <div class="feature-cell color-gray">○ 门店营业趋势分析</div>
          <div class="feature-cell color-gray">○ 分类统计图表</div>

          <div class="feature-cell color-gray">○ 导购销售报表</div>
          <div class="feature-cell color-gray">○ 营业额支付方式统计表</div>
          <div class="feature-cell color-gray">○ 商品销售热力图</div>
          <div class="feature-cell color-gray">○ 商品销售统计表</div>

          <div class="feature-cell color-gray">○ 商品分类消费统计</div>
          <div class="feature-cell color-gray">○ 商品消费时段分析报表</div>
          <div class="feature-cell color-gray">○ 商品折扣报表</div>
          <div class="feature-cell color-gray">○ 会员充值明细表</div>

          <div class="feature-cell color-gray">○ 会员储值汇总表</div>
          <div class="feature-cell color-gray">○ 会员余额对账表</div>
          <div class="feature-cell color-gray">○ 次卡消费报表</div>
          <div class="feature-cell color-gray">○ 购物卡消费报表</div>

          <div class="feature-cell color-gray">○ 积分使用核对表</div>
          <div class="feature-cell color-gray">○ 会员消费历史对账表</div>
          <div class="feature-cell color-gray">○ 赊账报表</div>
          <div class="feature-cell color-gray">○ 会员消费频次表</div>

          <div class="feature-cell color-gray">○ 会员消费占比图</div>
          <div class="feature-cell color-gray">○ 交接班记录</div>
          <div class="feature-cell color-gray">○ 现金收支记录</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">员工管理</div>

          <div class="feature-cell color-gray">○ 员工基础信息管理</div>
          <div class="feature-cell color-gray">○ 员工权限管理</div>
          <div class="feature-cell color-gray">○ 导购员基础信息管理</div>
          <div class="feature-cell color-gray">○ 提成设置</div>

          <div class="feature-cell color-gray">○ 业绩查询</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">团购、外卖对接</div>

          <div class="feature-cell color-gray">○ 美团验证设置</div>
          <div class="feature-cell color-gray">○ 大众点评验证设置</div>
          <div class="feature-cell color-gray">○ 百度糯米验证设置</div>
          <div class="feature-cell color-gray">○ 百度外卖订单对接设置</div>

          <div class="feature-cell color-gray">○ 美团外卖订单对接设置</div>
          <div class="feature-cell color-gray">○ 饿了么订单对接设置</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">系统设置</div>

          <div class="feature-cell color-gray">○ 会员支付密码开关设置</div>
          <div class="feature-cell color-gray">○ 会员固定金额设置</div>
          <div class="feature-cell color-gray">○ 0库存销售管理</div>
          <div class="feature-cell color-gray">○ 库存编辑权限设置</div>

          <div class="feature-cell color-gray">○ 收银零头处理</div>
          <div class="feature-cell color-gray">○ 消费税服务费设置</div>
          <div class="feature-cell color-gray">● 后台安全登录设置</div>
          <div class="feature-cell color-gray">○ 通知提醒设置</div>

          <div class="feature-cell color-gray">● 企业logo设置</div>
          <div class="feature-cell color-gray">○ 收银小票模板设置</div>
          <div class="feature-cell color-gray">○ 厨打小票模板设置</div>
          <div class="feature-cell color-gray">○ 系统操作日志</div>

          <div class="feature-cell color-gray">○ 门店通知</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">店教授收银系统前台</div>

          <div class="feature-subtitle color-black">通用收银功能</div>

          <div class="feature-cell color-gray">○ PC客户端点餐收银系统</div>
          <div class="feature-cell color-gray">○ iPad点餐收银系统</div>
          <div class="feature-cell color-gray">○ Android Pad点餐收银系统</div>
          <div class="feature-cell color-gray">○ iPhone点餐收银系统</div>

          <div class="feature-cell color-gray">○ Android手机点餐收银系统</div>
          <div class="feature-cell color-gray">○ 安卓平板自助点餐系统</div>
          <div class="feature-cell color-gray">○ 查看库存、盘点</div>
          <div class="feature-cell color-gray">○ 报损</div>

          <div class="feature-cell color-gray">○ 快捷打印标签</div>
          <div class="feature-cell color-gray">○ 商品添加、编辑</div>
          <div class="feature-cell color-gray">○ 订货、调货、退货</div>
          <div class="feature-cell color-gray">○ 锁屏、弹钱箱</div>

          <div class="feature-cell color-gray">○ 会员生日提醒</div>
          <div class="feature-cell color-gray">○ 广告播放</div>
          <div class="feature-cell color-gray">● 微信自助点餐系统</div>
          <div class="feature-cell color-gray">● 手机APP自助点餐系统</div>

          <div class="feature-cell color-gray">○ 快捷收银</div>
          <div class="feature-cell color-gray">○ 并桌收银</div>
          <div class="feature-cell color-gray">○ 换桌</div>
          <div class="feature-cell color-gray">○ 组合支付</div>

          <div class="feature-cell color-gray">○ 无码收银</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">餐饮版收银系统</div>

          <div class="feature-cell color-gray">○ 挂单取单</div>
          <div class="feature-cell color-gray">○ 开台</div>
          <div class="feature-cell color-gray">○ 划菜</div>
          <div class="feature-cell color-gray">○ 催菜</div>

          <div class="feature-cell color-gray">○ 上菜计时</div>
          <div class="feature-cell color-gray">○ 估清</div>
          <div class="feature-cell color-gray">○ 交接班</div>
          <div class="feature-cell color-gray">● 服务评价管理</div>

          <div class="feature-cell color-gray">● 菜品点评</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-subtitle color-black">零售版收银系统</div>

          <div class="feature-cell color-gray">○ 商品扫码收银</div>
          <div class="feature-cell color-gray">○ 批发与零售模式切换</div>
          <div class="feature-cell color-gray">○ 商品点评</div>
          <div class="feature-cell color-gray">○ 电子秤（条码称、通讯称）</div>

          <div class="feature-cell color-gray">● 会员寄存及取件</div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">我的店铺APP</div>

          <div class="feature-subtitle color-black">移动办公系统</div>

          <div class="feature-cell color-gray">○ 营业情况实时推送服务</div>
          <div class="feature-cell color-gray">○ 营业概况查询</div>
          <div class="feature-cell color-gray">○ 销售分类报表查询</div>
          <div class="feature-cell color-gray">○ 手机订货</div>

          <div class="feature-cell color-gray">○ 手机进货</div>
          <div class="feature-cell color-gray">○ 店铺监控手机端在线观看</div>
          <div class="feature-cell color-gray">○ 手机远程控制权限</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">微信店铺</div>

          <div class="feature-subtitle color-black">微信店铺平台</div>

          <div class="feature-cell color-gray">● 微信外卖平台</div>
          <div class="feature-cell color-gray">● 微信预约平台</div>
          <div class="feature-cell color-gray">● 微信自助服务</div>
          <div class="feature-cell color-gray">● 微信会员打通</div>

          <div class="feature-cell color-gray">● 会员在线充值</div>
          <div class="feature-cell color-gray">● 会员在线查询</div>
          <div class="feature-cell color-gray">● 在线结算设置</div>
          <div class="feature-cell color-gray">● 消费历史查询</div>

          <div class="feature-cell color-gray">● 微信通知服务</div>
          <div class="feature-cell color-gray">● 微信营销</div>
          <div class="feature-cell color-gray">● 微信签到</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">KDS智能厨打APP</div>

          <div class="feature-subtitle color-black">智能厨打</div>

          <div class="feature-cell color-gray">◆ 多厨打分菜管理系统</div>
          <div class="feature-cell color-gray">◆ 追菜优先排序管理</div>
          <div class="feature-cell color-gray">◆ ADS配菜管理系统</div>
          <div class="feature-cell color-gray">◆ KDS智能平板厨打管理</div>

          <div class="feature-cell color-gray">◆ 取餐呼叫管理</div>
          <div class="feature-cell color-gray">◆ 烹饪时间统计</div>
          <div class="feature-cell color-gray">◆ 烹饪时间预警</div>
          <div class="feature-cell color-gray">◆ TV广告服务</div>

          <div class="feature-title color-orange">技术支持</div>

          <div class="feature-subtitle color-black">服务</div>

          <div class="feature-cell color-gray">● 专属1对1技术支持</div>
          <div class="feature-cell color-gray">● 系统部署、迁移服务</div>
          <div class="feature-cell color-gray">● 开放API</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-title color-orange">移动CRM</div>

          <div class="feature-subtitle color-black">移动CRM</div>

          <div class="feature-cell color-gray">● 查看门店销售员排名及贡献</div>
          <div class="feature-cell color-gray">● 会员精准营销管理</div>
          <div class="feature-cell color-gray">● 销售员社交分享</div>
          <div class="feature-cell color-gray"></div>

          <div class="feature-footnote color-orange">
            ○为专业版功能，●为在专业版基础上企业版特别功能，◆为KDS APP特别功能。
          </div>

          <div id="feature-close" class="feature-close color-orange">
            <div>关闭功能介绍</div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; height: 1px; visibility: hidden"></div>

    <!-- <link rel="stylesheet" href="/main/resource/css/common/case.css" /> -->

    <div class="section case-section-1">
      <div class="content">
        <div class="text">
          <div class="text-1 color-black">12</div>
          <div class="text-2 color-black">10000</div>
          <div class="text-3 color-black">7×12</div>
        </div>
      </div>
    </div>

    <div class="section case-section-2">
      <div class="content">
        <div class="text">
          <div class="text-1 color-black">10000企业都在用</div>
          <div class="text-2 color-black">
            <a href="">查看所有案例 ></a>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-11">
      <div class="content">
        <div class="text">
          <div class="title color-black">选择账户类型，立即开始体验</div>

          <div class="text-1">免费账户</div>
          <div class="text-2">专业版账户</div>
          <div class="text-3">一步到位，简单够用</div>
          <div class="text-4">1天1元，就获得一套专业门店管理解决方案</div>

          <div class="text-5">营业报表</div>
          <div class="text-6">员工管理</div>
          <div class="text-7">进销存</div>
          <div class="text-8">网络商城</div>
          <div class="text-9">会员管理</div>

          <div class="text-10">所有免费功能</div>
          <div class="text-11">会员与CRM营销</div>
          <div class="text-12">货流与供应链</div>
          <div class="text-13">专属技术支持</div>
          <div class="text-14">原材料管控</div>
          <div class="text-15">旧数据迁移服务</div>

          <div class="text-16">免费</div>
          <div class="text-17">
            <span style="font-size: 48px">¥</span>
            <span style="font-size: 60px">1</span>
            <span style="font-size: 20px">/天</span>
          </div>

          <div>
            <a
              id="sign-up-2"
              class="sign-up"
              >立即注册</a
            >
          </div>
          <div>
            <a
              id="upgrade"
              class="upgrade"
              >升级到专业版</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import '../../../../public/static/css/cloud/common.css'
// import '../../../../public/static/css/common/case.css'
// import '../../../../public/static/css/common/common.css'
import '../../../assets/css/cloud/common.css'
import '../../../assets/css/common/common.css'
import '../../../assets/css/common/case.css'
import '../../../assets/js/jquery/jquery.js'
import '../../../assets/js/common/common.js'
export default {};
</script>

<style scoped>
.section-1 {
  height: 735px;
  background-image: url('../../../assets/images/product/cloud/section_1.jpg');
}
.section-1 .content .text {
  position: absolute;
  width: 760px;
  top: 230px;
  left: 400px;
}
.section-1 .content .text .text-1 {
  font-size: 60px;
  text-align: right;
  letter-spacing: 2px;
}
.section-1 .content .text .text-2 {
  font-size: 30px;
  text-align: right;
  margin: 50px 0 0 0;
  letter-spacing: 2px;
}
.section-1 .content .text .text-3 {
  font-size: 24px;
  text-align: right;
  margin: 20px 0 0 0;
  letter-spacing: 2px;
}
.section-1 .content .text .text-4 {
  font-size: 24px;
  text-align: right;
  margin: 20px 80px 0 0;
}
.section-1 .content .text .experience {
  position: absolute;
  display: block;
  font-size: 20px;
  width: 245px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  margin: 40px 0 0 0;
  background-color: #ff6727;
  border-radius: 2px;
  right: 0;
}
.section-2 {
  height: 1011px;
  background-image: url("../../../assets/images/product/cloud/section_2.jpg");
}
.section-2 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 140px;
}
.section-2 .content .text .text-4,
.section-2 .content .text .text-5,
.section-2 .content .text .text-6,
.section-2 .content .text .text-7 {
  position: absolute;
  width: 150px;
  font-size: 20px;
  top: 600px;
  text-align: center;
}
.section-2 .content .text .text-4 {
  left: 100px;
}
.section-2 .content .text .text-5 {
  left: 385px;
}
.section-2 .content .text .text-6 {
  left: 665px;
}
.section-2 .content .text .text-7 {
  left: 945px;
}
.section-3 {
  height: 787px;
  background-image: url("../../../assets/images/product/cloud/section_3.jpg");
}
.section-3 .content .text {
  position: absolute;
  width: 100%;
  height: 400px;
  top: 180px;
  left: 50px;
}
.section-3 .content .text .text-5 {
  margin: 70px 0 0 0;
}
.section-3 .content .text .text-5 .app-android,
.section-3 .content .text .text-5 .app-ios {
  display: inline-block;
  width: 184px;
  height: 48px;
  line-height: 48px;
  margin: 0 30px 0 0;
}
.section-3 .content .text .text-4 {
  position: absolute;
  font-size: 18px;
  margin: 20px 0 0 0;
}
.section-3 .content .text .text-5 .app-ios {
  background-image: url("../../../assets/images/product/cloud/button_app_ios.png");
}
.section-3 .content .text .text-5 .app-android {
  background-image: url("../../../assets/images/product/cloud/button_app_android.png");
}
.section-3 .content .text .text-5 .app-android .link-text,
.section-3 .content .text .text-5 .app-ios .link-text {
  margin: 0 0 0 65px;
}
.section-4 {
  height: 787px;
  background-image: url("../../../assets/images/product/cloud/section_4_1.jpg");
}
.section-4 .text {
  position: absolute;
  width: 800px;
  height: 400px;
  top: 330px;
  left: 190px;
}
.section-4 .text .link a {
  display: block;
  font-size: 18px;
  height: 54px;
  line-height: 54px;
  padding: 0 0 0 80px;
  background-size: 54px 54px;
}
.section-4 .text .link-1 a {
  background-image: url("../../../assets/images/product/cloud/catering_edition.png");
}
.section-4 .text .link-1 a:hover {
  color: #ff6727;
  /* background-image: url("../../../assets/images/product/cloud/catering_edition_hover.png"); */
  background-size: 54px 54px;
}
.section-4 .text .link-2 {
  margin: 60px 0 0 0;
}
.section-4 .text .content {
  position: absolute;
  top: -160px;
  left: 460px;
}
.section-4 .text .content .text-4 {
  font-size: 18px;
  margin: 60px 0 0 0;
}
.section-4 .text .content-2 {
  display: none;
}
.section-4 .text .link-2 {
  margin: 40px 0 0 0;
}
.section-4 .text .link-2 a {
  background-image: url("../../../assets/images/product/cloud/retailing_edition.png");
}
.section-4 .text .link-2 a:hover {
  color: #ff6727;
  /* background-image: url("../../../assets/images/product/cloud/retailing_edition_hover.png"); */
  background-size: 54px 54px;
}
.section-5 {
  height: 761px;
  background-image: url("../../../assets/images/product/cloud/section_5.jpg");
}
.section-5 .content .text {
  position: absolute;
  width: 700px;
  height: 400px;
  text-align: left;
  top: 200px;
  left: 680px;
}
.section-6 {
  height: 578px;
  background-image: url("../../../assets/images/product/cloud/section_6.jpg");
}
.section-6 .content .text {
  position: absolute;
  width: 100%;
  height: 400px;
  top: 160px;
  left: 50px;
}
.section-7 {
  height: 999px;
  background-image: url("../../../assets/images/product/cloud/section_7.jpg");
}
.section-7 .content .text {
  position: absolute;
  width: 100%;
  height: 400px;
  text-align: center;
  top: 150px;
}
.section-7 .content .text .text-4 {
  font-size: 20px;
  margin: 40px auto 0 auto;
}
.section-8 {
  height: 680px;
  background-image: url("../../../assets/images/product/cloud/section_8.jpg");
}
.section-8 .content .text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 125px;
}
.section-8 .content .text .title {
  font-size: 40px;
  letter-spacing: 1px;
}
.section-8 .content .text .sub-text-1 {
  position: absolute;
  width: 400px;
  top: 230px;
  left: -30px;
  letter-spacing: 1px;
}
.section-8 .content .text .sub-text-1 .text-1 {
  font-size: 24px;
  text-align: center;
}
.section-8 .content .text .sub-text-1 .text-2 {
  font-size: 16px;
  margin: 20px 0 0 30px;
  text-align: left;
  line-height: 30px;
}
.section-8 .content .text .sub-text-2 {
  position: absolute;
  width: 400px;
  top: 230px;
  left: 400px;
  letter-spacing: 1px;
}
.section-8 .content .text .sub-text-2 .text-1 {
  font-size: 24px;
  text-align: center;
}
.section-8 .content .text .sub-text-2 .text-2 {
  font-size: 16px;
  margin: 20px 0 0 50px;
  text-align: left;
  line-height: 30px;
}
.section-8 .content .text .sub-text-3 {
  position: absolute;
  width: 400px;
  top: 230px;
  left: 801px;
}
.section-8 .content .text .sub-text-3 .text-1 {
  font-size: 24px;
  text-align: center;
}
.section-8 .content .text .sub-text-3 .text-2 {
  font-size: 16px;
  margin: 20px 0 0 50px;
  text-align: left;
  line-height: 30px;
}
.section-8 .content .text .feature-open {
  top: 420px;
}
.section-9 {
  height: 0;
  background-color: #f4f4f6;
}
.section-11 {
  height: 1016px;
  background-image: url("../../../assets/images/product/cloud/section_10.jpg");
}
.section-11 .content .text {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 100px;
  left: 50px;
}
.section-11 .content .text .title {
  font-size: 56px;
  width: 100%;
  text-align: center;
}
.section-11 .content .text .text-1 {
  color: #31ba8e;
  font-size: 30px;
  left: 160px;
  position: absolute;
  top: 220px;
  width: 200px;
  text-align: center;
}
.section-11 .content .text .text-2 {
  color: #ff6727;
  font-size: 30px;
  left: 740px;
  position: absolute;
  top: 220px;
  width: 200px;
  text-align: center;
}
.section-11 .content .text .text-3,
.section-11 .content .text .text-4 {
  color: #737373;
  font-size: 18px;
  position: absolute;
  text-align: center;
  top: 280px;
  width: 500px;
}
.section-11 .content .text .text-3 {
  left: 10px;
}
.section-11 .content .text .text-4 {
  left: 590px;
}
.section-11 .content .text .text-10,
.section-11 .content .text .text-11,
.section-11 .content .text .text-12,
.section-11 .content .text .text-13,
.section-11 .content .text .text-14,
.section-11 .content .text .text-15,
.section-11 .content .text .text-5,
.section-11 .content .text .text-6,
.section-11 .content .text .text-7,
.section-11 .content .text .text-8,
.section-11 .content .text .text-9 {
  position: absolute;
  color: #737373;
  font-size: 16px;
  width: 200px;
}
.section-11 .content .text .text-5 {
  left: 170px;
  top: 366px;
}
.section-11 .content .text .text-6 {
  left: 330px;
  top: 366px;
}
.section-11 .content .text .text-7 {
  left: 170px;
  top: 406px;
}
.section-11 .content .text .text-8 {
  left: 330px;
  top: 406px;
}
.section-11 .content .text .text-9 {
  left: 170px;
  top: 446px;
}
.section-11 .content .text .text-10 {
  left: 720px;
  top: 366px;
}
.section-11 .content .text .text-11 {
  left: 880px;
  top: 366px;
}
.section-11 .content .text .text-12 {
  left: 720px;
  top: 406px;
}
.section-11 .content .text .text-13 {
  left: 880px;
  top: 406px;
}
.section-11 .content .text .text-14 {
  left: 720px;
  top: 446px;
}
.section-11 .content .text .text-15 {
  left: 880px;
  top: 446px;
}
.section-11 .content .text .text-16,
.section-11 .content .text .text-17 {
  color: #000;
  font-size: 50px;
  position: absolute;
  width: 160px;
  text-align: center;
}
.section-11 .content .text .text-16 {
  left: 180px;
  top: 500px;
}
.section-11 .content .text .text-17 {
  left: 750px;
  top: 500px;
}
.section-11 .content .text .sign-up,
.section-11 .content .text .upgrade {
  position: absolute;
  font-size: 20px;
  display: block;
  width: 280px;
  height: 80px;
  line-height: 80px;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  top: 610px;
}
.section-11 .content .text .sign-up {
  left: 120px;
  background-color: #31ba8e;
}
.section-11 .content .text .upgrade {
  left: 690px;
  background-color: #ff6727;
}
.mr30{
    margin: 30px 0 0 0;
}
</style>